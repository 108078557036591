
.item-with-tip {
    position: relative;
   
    .tip {
        display: none;
        position: absolute;
        left: 100%;
        top: 0;
        margin-left: 4px;
        width: fit-content;
        background: #000;
        word-break: keep-all;
        padding: 2px 6px;
        font-size: 14px;
        line-height: 30px;
        border: 1px solid #fff;
        border-radius: 4px;
        z-index: 999;
    }

    &:hover {
        .tip {
            display: block;
        }
    }
}